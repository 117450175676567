import { colors } from "../contants";
import styled from "styled-components";
import backgroundImage from "../assets/logobg.png";

export const HeroContainer = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 70rem;
    @media (max-width: 600px) {
      height: 65rem;
    }
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;


export const HeroLogoImage = styled.img`
    width: 100%;
`;


export const Logo = styled.div`
    position: absolute;
    width: 55%;
    @media (max-width: 600px) {
      width: 80%;
      top: 25%;
    }
    display: flex;
    justify-content: center;
    top: 15%;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 75%;
    
    @media (max-width: 600px) {
      top: 70%;
    }
`;

export const VideoButton = styled.button`


  color: ${(props) => (props.$active ? "black" : colors.textColor)};
  background-color: ${(props) =>
    props.$active ? colors.primaryColor : colors.dark300};
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 1rem;
  transition: background-color 0.5s ease;
  padding: 1.4rem 3rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    padding: 1.2rem 2.5rem;
    font-size: 1.3rem;
  }

  ${(props) =>
    !props.$active &&
    `
    &:hover {
      background-color: ${colors.secondaryColor};
    }
  `}

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const HeroWrapper = styled.div`
    position: relative;
`;


