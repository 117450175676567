import styled from "styled-components";
import { Lock } from "@styled-icons/material";
import { iconPaths } from "../contants";
import MyIcon from "../assets/footerInstagram.svg";
import backgroundImage from "../assets/bg.jpg";
import { colors } from "../contants";

export const infoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

export const StyledIcon = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${MyIcon});
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
`;

export const infoContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  @media (max-width: 1400px) {
    height: 50rem;
  }
  @media (max-width: 600px) {
    height: 90rem;
  }

  
`;

export const infoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding = 10px;
  margin: 20px;
  
`;

export const infoImg = styled.img`
  height: 100px;
  width: 100px;
`;

export const infoTitle = styled.h3`
  font-size: 16px;
  margin-top: 10px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
`;

export const infoContent = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
`;
