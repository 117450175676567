import * as sc from "../styled/scFooter";
import Logo from "../assets/logo.png";
import {Instagram} from "@styled-icons/boxicons-logos/Instagram";
import {Twitter} from "@styled-icons/boxicons-logos/Twitter";
import {Linkedin} from "@styled-icons/boxicons-logos/Linkedin";

const Footer = () => (
    <sc.FooterContainer id="footer">
        <sc.FooterLeft>
            <sc.FooterImg src={Logo}/>
            <sc.FooterText>iletisim@acmhacettepe.com</sc.FooterText>
        </sc.FooterLeft>
        <sc.FooterLeft>
            <sc.FooterText2>Bizi takip edin.</sc.FooterText2>
            <sc.IconBox>
                <a href="https://www.instagram.com/acmhacettepe/"> 
                <sc.IconButton>
                    <Instagram/>
                </sc.IconButton>
                </a>
                <a href="https://twitter.com/acmhacettepe"> 
                <sc.IconButton >
                    <Twitter/>
                </sc.IconButton>
                </a>
                <a href="https://www.linkedin.com/company/acm-hacettepe/"> 
                <sc.IconButton>
                    <Linkedin/>
                </sc.IconButton>
                </a>
            </sc.IconBox>
        </sc.FooterLeft>
    </sc.FooterContainer>
  );
  
  export default Footer;