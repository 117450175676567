import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../contants";

export const CounterWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;

`;

export const CounterHeader = styled.h2`
  font-size: 3.7rem;
  margin-top: 7rem;
  margin-bottom: 1.8rem;
  text-align: center;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.4rem;
  margin-bottom: 7rem;
  background: white;
  width: 100%;
  @media (max-width: 600px) {
    padding: 2rem;
    flex-wrap: wrap;
  }
`;

export const ElementContainer = styled.div`
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin: 0 1.4rem;
  text-transform: uppercase;

  width: 11rem;
  height: 11rem;

  font-size: 1.5rem;
  text-align: center;

  border-top-right-radius: 2px;
  box-shadow: 1px 10px 20px rgba(0, 0, 0, 0.2);

  strong {
    font-size: 3.5rem;
    marign-bottom: 3rem;
  

    @media (max-width: 600px) {
      font-size: 2.2rem;
    }
  }

  span {
    margin-top: 14px;
    color: black;
  }


  @media (max-width: 600px) {
    margin-top: 3rem;
  }
  
`;
