//import { useEffect } from "react";
import { GlobalStyles } from "./styled/scApp";
import Attendants from "./components/Attendants";
import Counter from "./components/Counter";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Info from "./components/Info";
import Navbar from "./components/Navbar";
import Schedule from "./components/Schedule";
import Sponsors from "./components/Sponsors";


const App = () => {

  // animations will come here


  return (
    <main>
      <GlobalStyles />
      <Navbar/>
      <Hero/>
      <Counter/>
      <Info/>
      <Schedule/>
      <Attendants/>
      <Sponsors/>
      <Footer/>
    </main>
  );
}

export default App;