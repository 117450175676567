import * as sc from "../styled/scHero";
import { eventLogo } from "../contants";

const Hero = () => (
  <sc.HeroWrapper>

    <sc.HeroContainer>

      <sc.Logo>
        <sc.HeroLogoImage
          className="hero-image"
        // src={eventLogo}
        />
      </sc.Logo>

      <sc.ButtonContainer>
        <a href="https://www.youtube.com/channel/UCj9lz6_BSlGaiiq1IySGo_A">
          <sc.VideoButton>
            ACM HACETTEPE YOUTUBE
          </sc.VideoButton>
        </a>
      </sc.ButtonContainer>

    </sc.HeroContainer>

  </sc.HeroWrapper>

);

export default Hero;

