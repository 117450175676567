import Logo21 from "../assets/smsLogo.png";
import Logo from "../assets/sms23.png";
// import Logo from "../assets/sms24.png";
import LogoBG from "../assets/logobg.png";

import MervanFiratImage from "../assets//people/mervancharm.png";
import InancKonukcuImage from "../assets//people/inanç.png";
import MerveBolugurImage from "../assets//people/merve.png";
import JokerImage from "../assets//people/joker.png";
import TunahanAkImage from "../assets//people/tunahan.png";
import AhsenBucekImage from "../assets//people/ahsen.png";
import SelinImage from "../assets//people/selin.png";
import BaturayOzdemirImage from "../assets//people/baturay.png";

import InstagramIcon from "../assets/footerInstagram.svg";
import LinkedinIcon from "../assets/footerLinkedin.svg";
import TwitterIcon from "../assets/footerTwitter.svg";

import Taleworlds from "../assets/sponsors/ana/taleworlds.png";
import Sherin from "../assets/sponsors/platin/sherin.png";
import MADPerfumeur from "../assets/sponsors/altin/mad-perfumeur.png";
import ABB from "../assets/sponsors/fuaye/abb.png";
import Chupachups from "../assets/sponsors/fuaye/chupachups.png";
import Cizmeci from "../assets/sponsors/fuaye/cizmeci.png";
import Dimes from "../assets/sponsors/fuaye/dimes.png";
import Duru from "../assets/sponsors/fuaye/duru.png";
import GoldHarvest from "../assets/sponsors/fuaye/gold-harvest.webp";
import Kotex from "../assets/sponsors/fuaye/kotex.png";
import MRNo from "../assets/sponsors/fuaye/mr.no.png";
import MRB from "../assets/sponsors/fuaye/mrb.jpeg";
import Reeder from "../assets/sponsors/fuaye/reeder.png";
import Rosece from "../assets/sponsors/fuaye/rosece.png";
import Serince from "../assets/sponsors/fuaye/serince.png";
import ThePurest from "../assets/sponsors/fuaye/the-purest.png";
import TeaCo from "../assets/sponsors/fuaye/teaco.png";
import FourB from "../assets/sponsors/cekilis/fourb.png";
import Maruderm from "../assets/sponsors/urun/maruderm.png";
import Kampusetkinlikleri from "../assets/sponsors/medya/kampusetkinlikleri.png";
import Muzik from "../assets/sponsors/medya/muzik.jpg";
import Youthall from "../assets/sponsors/medya/youthall.png";

import map from "../assets/icons/map.svg";
import clock from "../assets/icons/clock.svg";
import peopleGroup from "../assets/icons/peopleGroup.svg";
import heartLike from "../assets/icons/heartLike.svg";

import { Facebook } from "styled-icons/boxicons-logos";
import { EyePlus } from "styled-icons/icomoon";

export const eventProviderLink = "https://www.acmhacettepe.com";
export const eventProvider = "ACM Hacettepe";
export const eventName = (
  <>
    SMS <span>24</span>
  </>
);
export const eventDate = "4-5 Nisan 2024";
export const eventLogo = Logo;
export const eventLogo21 = Logo21;
export const eventLogoBG = LogoBG;
export const eventDetails =
  "ACM Hacettepe Öğrenci Topluluğu olarak her sene düzenlediğimiz SMS etkinliği, 4-5 Nisan tarihlerinde düzenlenecek GELİŞİM V9 ile devam ediyor. Her sene olduğu gibi bu yılki GELİŞİM etkinliği de alanında uzman katılımcıların tecrübeleri, geçmişleri ve bilgi birikimleri üzerine yapacakları sunumlarla öğrencilerin hayatlarına farklı bakış açıları getirmeyi hedefliyor.";
export const contactMessage =
  "Etkinlik hakkında herhangi bir istek, öneriniz veya merak ettikleriniz hakkında bize mail yolu ile ulaşabilirsiniz.";
export const contactMailLink = "mailto:iletisim@acmhacettepe.com";

export const navigationItems = [
  {
    name: "Anasayfa",
    to: "#home",
  },
  {
    name: "Program",
    to: "#schedule",
  },
  {
    name: "Konuşmacılar",
    to: "#attendants",
  },
  {
    name: "Sponsorlar",
    to: "#sponsors",
  },
  {
    name: "Bize Ulaşın",
    to: "#footer",
  },
];

export const footerSocialLinks = [
  {
    link: "https://twitter.com/acmhacettepe?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    icon: TwitterIcon,
  },
  {
    link: "https://www.instagram.com/acmhacettepe/",
    icon: InstagramIcon,
  },
  {
    link: "https://www.linkedin.com/company/acm-hacettepe/mycompany/",
    icon: LinkedinIcon,
  },
];

export const dates = ["4 Nisan", "5 Nisan"];

export const participants = [
  {
    name: "Mervan Tepelioğlu & Fırat Yayla",
    description: "Charmqueel",
    date: dates[0],
    time: "12:00",
    detail: "",
    img: MervanFiratImage,
  },
  {
    name: "İnanç Konukçu",
    description: "Behzat Ç.nin Hayalet'i",
    date: dates[0],
    time: "13:15",
    detail: "",
    img: InancKonukcuImage,
  },
  {
    name: "Merve Boluğur",
    description: "Grafik Sanatçısı",
    date: dates[0],
    time: "14:30",
    detail: "",
    img: MerveBolugurImage,
  },
  {
    name: "JOKER",
    description: "",
    date: dates[0],
    time: "15:45",
    detail: "",
    img: JokerImage,
  },
  {
    name: "Tunahan Ak",
    description: "Matematikçi",
    date: dates[1],
    time: "12:00",
    detail: "",
    img: TunahanAkImage,
  },
  {
    name: "Ahsen Bücek",
    description: "Influencer",
    date: dates[1],
    time: "13:15",
    detail: "",
    twitter: "https://twitter.com/fsobutay",
    instagram: "https://www.instagram.com/firatsobutay/",
    img: AhsenBucekImage,
  },
  {
    name: "Selin",
    description: "Grafik Sanatçısı",
    date: dates[1],
    time: "14:30",
    detail: "",
    twitter: "https://mobile.twitter.com/ktevren",
    instagram: "https://www.instagram.com/kuntaytarikevren/",
    img: SelinImage,
  },
  {
    name: "Baturay Özdemir",
    description: "Spor Yayıncısı",
    date: dates[1],
    time: "15:45",
    detail: "",
    img: BaturayOzdemirImage,
  },
];
export const sponsorTypes = [
  "Dönem Ana Sponsorumuz",
  "Platin Sponsorumuz",
  "Altın Sponsorumuz",
  "Fuaye Sponsorlarımız",
  "Ürün Sponsorumuz",
  //"Çekiliş Sponsorlarımız",
  //"Medya Sponsorlarımız",
];
export const sponsors = [
  {
    title: sponsorTypes[0],
    link: "https://www.taleworlds.com/",
    image: Taleworlds,
    bgColor: '#000000',
  },
  {
    title: sponsorTypes[1],
    link: "https://www.sherin.com.tr/",
    image: Sherin,
    bgColor: "#FCFCFC",

  },
  {
    title: sponsorTypes[2],
    link: "https://www.madparfum.com/",
    image: MADPerfumeur,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    link: "https://www.ankara.bel.tr/",
    image: ABB,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    link: "https://cizmecitime.com/",
    image: Cizmeci,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Chupachups,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Dimes,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Duru,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: GoldHarvest,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Kotex,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Maruderm,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: MRNo,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: MRB,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Reeder,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Rosece,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: Serince,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: ThePurest,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[3],
    image: TeaCo,
    bgColor: "#FCFCFC",
  },
  {
    title: sponsorTypes[4],
    image: Maruderm,
    bgColor: "#FCFCFC",
  },
  // {
  //   title: sponsorTypes[5],
  //   image: FourB,
  //   bgColor: "#FCFCFC",
  // },

  // {
  //   title: sponsorTypes[6],
  //   link: "https://kampusetkinlikleri.com/",
  //   image: Kampusetkinlikleri,
  //   bgColor: "#FCFCFC",
  // },
  // {
  //   title: sponsorTypes[6],
  //   link: "https://muzikonair.com/",
  //   image: Muzik,
  //   bgColor: "#FCFCFC",
  // },
  // {
  //   title: sponsorTypes[6],
  //   link: "https://www.youthall.com/tr/",
  //   image: Youthall,
  //   bgColor: "#FCFCFC",
  // },
];

export const iconPaths = {
  lnimap:
    "M12,0.3C7,0.3,3,4.3,3,9.3c0,6.7,9,14.4,9,14.4s9-7.7,9-14.4C21,4.3,17,0.3,12,0.3z M12,12.5c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C16,10.7,14.2,12.5,12,12.5z",
  menu: "M3 18h18M3 12h18M3 6h18",
  home: "M12 20.984L19.328 13H22v-8h-6.984L12 6.016 6.984 5.016H0v8h2.672L12 20.984z",
  search:
    "M15.5 14h-.79l-.28-.27c1.2000000000000002-1.38 1.9599999999999997-3.18 1.9599999999999997-5.23 0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8c2.05 0 3.85-.76 5.23-1.96l.27.28v.79l4.25 4.25c.39.39 1.02.39 1.41 0l.73-.73c.39-.39.39-1.02 0-1.41L15.5 14zM6 12c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z",
};

export const infoData = [
  {
    icon: map,
    title: "2 Gün - 8 Oturum",
    content: "HACETTEPE ÜNİVERSİTESİ",
    content1: "MEHMET AKİF ERSOY K SALONU",
  },
  {
    icon: clock,
    title: "4-5 NİSAN",
    content: "2024",
    content1: "12:30 – 18:00",
  },
  {
    icon: peopleGroup,
    title: "8 Oturum",
    content: "9 KONUŞMACI",
    content1: "SOSYAL MEDYANIN SEVİLEN YÜZLERİ",
  },
  {
    icon: heartLike,
    title: "KEYİFLİ GEÇECEK 2 GÜN",
    content: "SEN DE YERİNİ AL!",
    content1: " ",
  },
];

export const colors = {
  primaryColor: "white",
  secondaryColor: "#B0EECD",
  secondaryColorLight: "#fffefe",
  secondaryBackground: "#f2f5f3",
  textColor: "black",
  navbarTextColor: "#c9a0dc",
  borderColor: "rgba(255, 255, 255, .1)",
  popupColor: "#F0F3FB",
  popupOverlayColor: "rgba(0, 0, 0, .3)",
  dark300: "white",
  dark400: "black",
  pink1: "#DFC7ED"
};
