import styled from "styled-components";
import { colors } from "../contants";

export const AttendantsContainer = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 72%;
  allign-items: center;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const AttendantsWrapper = styled.div`
  background-color: ${colors.secondaryColorLight};
  padding-top: 5rem;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 4rem;
  margin-bottom: 2rem;
`;

export const AttendantsItem = styled.div`
  flex: 15%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem;
  width: 30rem;
  height: 40rem;
  

  @media only screen and (max-width: 1024px) {
    padding: 0;
  }

`;


export const AttendantsCard = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1rem;
  border-radius: 3px; 
  box-shadow: 0px 0px 5px 1px #888888;
  &: hover {
    box-shadow: 0px 5px 15px 2px #888888;
    transition-duration: 1s;
  }
  transition-duration: 0.4s;
`;


export const AttendantsDiv = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }

  
`;



export const AttendantImg = styled.img`
width: 30rem;
height: 30rem;
`;
export const AttendantName = styled.h3`
text-align: center;
font-size: 1.7rem;
`;

export const AttendantText = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 4rem;
  width: 16rem;
  background-color: ${colors.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;