import styled from "styled-components";
import { colors } from "../contants";

export const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 100000;
  background: ${colors.secondaryColor};

  ${(props) =>
    props.$sticky &&
    `
    padding: 5px 0;
    background: ${colors.secondaryColor};
    backdrop-filter: blur(9.7px);
    -webkit-backdrop-filter: blur(9.7px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  `}
`;

export const HeaderContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin: 0 auto;

  @media only screen and (min-width: 1500px) {
    max-width: 115rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderLogoLink = styled.a`
  display: inline-block;
  transition: transform 0.5s ease;
  cursor: pointer;

  ${(props) =>
    !props.$sticky &&
    `
    &:hover {
      transform: translateY(-5px);
    }
  `}
`;

export const HeaderLogo = styled.img`
  width: 12.5rem;
`;

export const HeaderNav = styled.ul`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 30rem;
    height: 100vh;
    background-color: black;
    flex-direction: column;
    align-items: flex-start;
    padding: 7.5rem 5rem 5rem;
    gap: 2rem;
    z-index: 99;
    transition: transform 0.5s ease;
    transform: translateX(100%);

    ${(props) =>
    props.$active &&
    `
      transform: none;
    `}
  }
`;

export const HeaderItem = styled.div`
  list-style: none;

  &:not(:last-child) {
    margin-right: 4rem;
  }
`;

export const HeaderItemLink = styled.a`
  font-size: 1.5rem;

  font-weight: 300;
  color: 'black';
  transition: color 0.5s ease;
  cursor: pointer;

  &:hover {
    color: ${colors.secondaryColor};
  }
`;

export const HeaderMobileContainer = styled.button`
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  outline: none;
  border: none;
  background: transparent;
  position: relative;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 5px;
  transition: transform 0.5s ease;

  @media (min-width: 1025px) {
    display: none;
  }

  ${(props) =>
    props.$active &&
    `
    transform: translateX(2rem);
  `}
`;

export const HeaderMobile = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: white;
  position: relative;

  ${(props) =>
    props.$active &&
    `
      &:before {
        transform: translateX(-175%);
      }

      &:after {
        transform: translateX(175%);
      }
  `}

  &:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: white;
    transition: transform 0.5s ease;
  }

  &:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: white;
    transition: transform 0.5s ease;
  }
`;
