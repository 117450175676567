import styled from "styled-components";
import { colors } from "../contants";

export const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    align-items: center;
    background-color: rgb(35, 35, 35);
    padding-left: 20%;
    padding-right: 25%;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
`;

export const IconBox = styled.div`
    width: 15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
`;


export const IconButton = styled.button`
    width: 4rem;
    height: 4rem;
    border-radius: 7px;
    background-color: white;
    &:hover {
        background-color: ${colors.secondaryColor};
    }
`;

export const FooterLeft = styled.div`
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FooterText = styled.h3`
    color: white;
    font-size: 1.3rem;
`;

export const FooterText2 = styled.h3`
    color: white;
    font-size: 1.6rem;
`;

export const FooterImg = styled.img`
    width: 20rem;
    margin-bottom: 5rem;
`;

