import { useState, useEffect } from "react";
import * as sc from "../styled/scSchedule";
import { dates, participants } from "../contants";
import dateIcon from "../assets/dateBlue.svg";

const Schedule = () => {
  const [activeDate, setActiveDate] = useState(dates[0]);

  return (
    <sc.ProgramWrapper id="schedule">
      <sc.ProgramTitle>ETKİNLİK TAKVİMİ</sc.ProgramTitle>
      <sc.ScheduleWrapper>
        <sc.ProgramContainer>
          <sc.ProgramButtonContainer>
            {dates.map((date, i) => (
              <sc.ProgramButton
                key={i}
                $active={activeDate === date}
                onClick={() => setActiveDate(date)}
              >
                {date}
              </sc.ProgramButton>
            ))}
          </sc.ProgramButtonContainer>

          <sc.ProgramMain>
            <sc.ParticipantsContainer>
              {participants.map(
                (participant, i) =>
                  activeDate === participant.date && (
                    <sc.Participant>
                      <sc.ParticipantImage
                        src={participant.img}
                        alt={participant.name}
                      />
                      <sc.ParticipantDetails>
                        <sc.ParticipantContent>
                          <sc.ParticipantName>
                            {participant.name}
                          </sc.ParticipantName>
                          <sc.ParticipantProgramDate>
                            {participant.time}
                          </sc.ParticipantProgramDate>
                        </sc.ParticipantContent>
                      </sc.ParticipantDetails>
                    </sc.Participant>
                  )
              )}
            </sc.ParticipantsContainer>
          </sc.ProgramMain>

        </sc.ProgramContainer>
      </sc.ScheduleWrapper>
    </sc.ProgramWrapper>
  );
}
  export default Schedule;