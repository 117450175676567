import * as sc from "../styled/scInfo";
import * as ico from "../styled/icon";

import { infoData } from "../contants";

const Info = () => (
  <sc.infoContainer>
      {infoData.map((data, i) => (
        <Card {...data} />
      ))}
  </sc.infoContainer>
);

const Card = ({ icon, title, content, content1 }) => (
  <sc.infoItem>
    <sc.infoCard>
      <ico.MyComponent iconName={icon} />
      <sc.infoTitle>{title}</sc.infoTitle>
      <sc.infoContent>{content}</sc.infoContent>
      <br></br>
      <sc.infoContent>{content1}</sc.infoContent>
    </sc.infoCard>
  </sc.infoItem>
);

export default Info;
