import styled from "styled-components";
import { colors } from "../contants";

export const SponsorsContainer = styled.div`
  width:100%;
  padding: 5rem;
  margin-top: 8rem;
  background-color: ${colors.secondaryBackground};
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  text-align: center;
  width: 100%;
  padding: 2rem;
  font-size: 4rem;
`;

export const SponsorType = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem;
  @media only screen and (max-width: 1024px) {
    padding: 0;
    padding-top: 4rem;
  }
  padding-top: 6rem;
`;

export const SponsorTitle = styled.h3`
  text-align: center;
  font-size: 2rem;
`;

export const SponsorItem = styled.div.attrs((props) => props)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    margin-bottom: 0;

    width: 13rem;
    height: 13rem;
    box-shadow: 0px 0px 5px 1px #888888;
    background: ${props => props.bg};
    @media only screen and (max-width: 1024px) {
      width: 10rem;
      height: 10rem;
      margin: 1rem;
    }
`;

export const SponsorImage = styled.img`
  width: 12rem;
  @media only screen and (max-width: 1024px) {
    width: 9rem;
  }
`;


export const TextContainer = styled.div`
width: 100%;
`;

