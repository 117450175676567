import * as sc from "../styled/scAttendants";
import { participants } from "../contants";

const Attendants = () => (
  <sc.AttendantsWrapper id="attendants">
  <sc.Title>TÜM KONUŞMACILAR</sc.Title>
  <sc.AttendantsContainer>
    {participants.map((attendant, i) => (
      <Card {...attendant}/>
    ))}
  </sc.AttendantsContainer>
  </sc.AttendantsWrapper>
  );

  const Card = ({ name, img, time, date }) => (
    <sc.AttendantsItem>
      <sc.AttendantsCard>
        <sc.AttendantsDiv>
          <sc.AttendantImg src={img} alt={name}/>
          <sc.AttendantText>
          <sc.AttendantName>{name}</sc.AttendantName>  
          </sc.AttendantText>
        </sc.AttendantsDiv>
      </sc.AttendantsCard>
    </sc.AttendantsItem>
  );
  
  export default Attendants;