import styled from "styled-components";
import { colors } from "../contants";

export const ProgramWrapper = styled.section`
  padding: 0;
  display: flex;
  
  flex-direction: column;
  padding-top: 5rem;

 

  background-color: ${colors.secondaryBackground};
`;

export const ScheduleWrapper = styled.section`
  padding: 0;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    padding: 0;
  }

`;

export const ProgramContainer = styled.div`
  width: 100%;
  max-width: 120rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

export const ProgramMain = styled.div`
  width: 100%;
  @media only screen and (max-width: 1200px) {
    padding: 8rem 5rem 8rem 5rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 5rem 5rem;
  }

  @media only screen and (max-width: 500px) {
    padding: 2rem 2.5rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgramButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 5rem 5rem;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    margin-left: 0rem;
    margin-bottom: 3rem;
    width: 80%;
  }

`;

export const ProgramButton = styled.button`
  color: ${(props) => (props.$active ? "white" : "black")};
  background-color: ${(props) =>
    props.$active ?  colors.pink1 : colors.primaryColor};
  font-size: 1.4rem;
  font-weight: 400;
  transition: background-color 0.5s ease;
  padding: 1.4rem 3rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }

  ${(props) =>
    !props.$active &&
    `
    &:hover {
      background-color: ${colors.secondaryColor};
    }
  `}

  width: 30rem;
  height: 6rem;
  border: none;
  &:not(:last-child) {
    border-bottom: 0.5px solid gray;
  }

`;

export const Participant = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 3rem;
  position: relative;
  background-color:white;

  &:not(:last-child) {
    border-bottom: 0.5px solid gray;
  }
  
  z-index: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;

  
    
  }
`;

/*
@media only screen and (max-width: 1200px) {
      width: 147.5%;
    }

    @media only screen and (max-width: 1100px) {
      width: 152.5%;
    }

    @media only screen and (max-width: 1024px) {
      display: none;
    }
*/

export const ParticipantContent = styled.div`
`;

export const ParticipantImage = styled.img`
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  transition: opacity 0.5s ease;

  @media only screen and (max-width: 600px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

export const ParticipantDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 2rem;

  @media only screen and (max-width: 500px) {
    margin-left: 1.5rem;
  }
`;

export const ParticipantName = styled.h4`
  color: black;
  font-size: 1.6rem;
  font-weight: 400;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const ParticipantProgramDate = styled.p`
  color: black;
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 1rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;



export const ParticipantsContainer = styled.div`
  width:100%;
`;

export const ProgramTitle = styled.h2`
  text-align: center;
  font-size: 4rem;
`;