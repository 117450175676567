import { useState, useEffect } from "react";
import * as sc from "../styled/scNavbar";
import Logo from "../assets/logo.png";
import { scrollTo } from "../animation";
import { eventProvider, eventProviderLink, navigationItems } from "../contants";

const Navbar = () => {
  const [sticky, setSticky] = useState(false);
  const [active, setActive] = useState(false);

  const stickyHeaderFn = () => {
    const scrollY = window.scrollY;
    if (scrollY >= 50 && !sticky) setSticky(true);
    else if (scrollY < 50 && sticky) setSticky(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", stickyHeaderFn);
    return () => document.removeEventListener("scroll", stickyHeaderFn);
  });

  return (
    <sc.HeaderWrapper $sticky={sticky} className="header">
      <sc.HeaderContainer>
        <sc.HeaderLogoLink $sticky={sticky} href={eventProviderLink}>
          <sc.HeaderLogo src={Logo} alt={eventProvider} />
        </sc.HeaderLogoLink>
        <sc.HeaderMobileContainer
          onClick={() => setActive((active) => !active)}
          $active={active}
        >
          <sc.HeaderMobile $active={active} />
        </sc.HeaderMobileContainer>
        <sc.HeaderNav $active={active}>
          {navigationItems.map(({ name, to }, i) => (
            <sc.HeaderItem key={i}>
              <sc.HeaderItemLink
                onClick={() => {
                  setActive(false);
                  scrollTo(to);
                }}
              >
                {name}
              </sc.HeaderItemLink>
            </sc.HeaderItem>
          ))}
        </sc.HeaderNav>
      </sc.HeaderContainer>
    </sc.HeaderWrapper>
  );
};

export default Navbar;
