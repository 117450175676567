import * as sc from "../styled/scSponsors";
import { sponsors, sponsorTypes } from "../contants";

const Sponsors = () => (
  <sc.SponsorsContainer id="sponsors">

    <sc.Title>SPONSORLAR</sc.Title>

    {sponsorTypes.map((type, i) => (
      <sc.SponsorType>

        <sc.TextContainer>
          <sc.SponsorTitle> {type} </sc.SponsorTitle>
        </sc.TextContainer>

        {sponsors.map((sponsor, i) => type === sponsor.title && (
          <sc.SponsorItem href={sponsor.link} bg={sponsor.bgColor}>
            <sc.SponsorImage src={sponsor.image} />
          </sc.SponsorItem>
        ))}

      </sc.SponsorType>


    ))}



  </sc.SponsorsContainer>
);

export default Sponsors;